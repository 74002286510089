import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import clienteAxios from '../../config/axios';
import axiosLogin from '../../config/axiosLogin';
import './login.css'
import cookie from 'react-cookies'

const Login = () => {

    const history = useHistory();
    const [error, setError] = useState('');
    const [formValues, setformValues] = useState({
        email: '',
        password: '',
    });

    const handleInputChange = (event) => {
        console.log('handleInputChange');
        setformValues({
            ...formValues,
            [event.target.name]: event.target.value
        })
    }


    const validateData = (event) => {
        event.preventDefault();
        console.log('validateData');
        let internalError = '';
        if (formValues.email === '' || formValues.email === undefined) {
            internalError = 'El usuario es requerido, ';
        }
        if (formValues.password === '' || formValues.password === undefined) {
            internalError = internalError + 'La contraseña es requerida, ';
        }
        if (internalError === '') {
            setError('');
            login(formValues);
        } else {
            setError(internalError);
        }
    }

    const login = async () => {
        try {

            let data = new FormData();
            data.append('email', formValues.email);
            data.append('password', formValues.password);

            const response = await axiosLogin.post(`login`, data);
            console.log('response..............', response);

            if (response.data.token) {
                const maxAge = 3600 * 24 * 15;
                const expires = new Date()
                expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);
                cookie.save('token', response.data.token, { path: "/", expires, maxAge });
                console.log();
                clienteAxios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
                history.push(`/admin`);


            } else if (response.data.message) {
                alert('usuario o contraseña no validos')
                return 'invalid';
            }


        } catch (error) {
            console.log('error', error);
            return 'error';
        }
    }

    return (
        <div className="loginMain">
            <div className="box">
                <strong><h4 className="welcome">BIENVENIDO</h4></strong>
                <strong><h4 className="diagnosis">COLOMBIA PRODUCTIVA</h4></strong>
                <form onSubmit={validateData}>
                    <div style={{ display: 'inline-flex' }}>
                        <input style={{ width: '320px' }} placeholder="Email" name="email" value={formValues.email} onChange={handleInputChange} type="text" />
                    </div>
                    <br />
                    <br />
                    <div style={{ display: 'inline-flex' }}>
                        <input style={{ width: '320px' }} placeholder="Contraseña" name="password" value={formValues.password} onChange={handleInputChange} type="password" />
                    </div>
                    <br />
                    <br />
                    {error !== '' && <div>{error}</div>}

                    <button type="submit" className="btn btn-primary sendLogin">Ingrese a la plataforma</button>
                </form>
            </div>

            <div className="footerLogin">
                <img style={{ transform: 'translate(0, 10px)' }} src={require('../../images/ui/footer.png').default} alt="" width="100%" />
            </div>
        </div>

    )
}

export default Login;
