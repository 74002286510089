import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { url } from '../../constantes/url';
import { useHistory } from 'react-router-dom';
import cookie from 'react-cookies'
import './adminDashboard.css'

const AdminDashboard = () => {

    const [companies, setcompanies] = useState("");
    const [companySelected, setcompanySelected] = useState("")
    const [companyInfo, setCompanyInfo] = useState("")
    const history = useHistory();

    useEffect(() => {
        getListCompanies();
    }, [])

    const getListCompanies = async () => {
        try {
            let response = await axios.get(`${url}companiesList`);
            console.log('response getListCompanies', response);
            setcompanies(response.data)
        } catch (error) {

        }
    }

    const selectCompany = async (event) => {
        console.log('event', event.target.value);
        setcompanySelected(event.target.value)
        try {
            let response = await axios.get(`${url}companyByName/${event.target.value}`);
            console.log('response selectCompany', response);
            setCompanyInfo(response.data[0])
        } catch (error) {

        }

    }

    const logOut = () => {
        cookie.remove('token', { path: '/' });
        history.push(`/login`);
    }

    const downloadReport = async () => {
        try {
            let response = await axios.get(`${url}report`);
            console.log('response', response);

            let xlsx = require('json-as-xlsx')
            let settings = {
                fileName: 'asistenciaTalleres', // Name of the spreadsheet
                extraLength: 30, // A bigger number means that columns will be wider
                writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
            }

            let data = [
                {
                    sheet: 'asistenciaTalleres',
                    columns: [
                        { label: 'Nombre', value: 'name' }, // Top level data
                        { label: 'Apellido', value: 'last_name' }, // Run functions
                        { label: 'Numero de identificacion', value: 'identification_document' }, // Deep props
                        { label: 'Empresa', value: 'company' }, // Deep props
                        { label: 'Tipo de entidad', value: 'company_type' }, // Deep props
                        { label: 'Nit', value: 'nit' }, // Deep props
                        { label: 'Telefono', value: 'phone' }, // Deep props
                        { label: 'Correo', value: 'email' }, // Deep props
                        { label: 'Taller', value: 'workshop' }, // Deep props
                    ],
                    content: response.data.presence
                }
            ]

            xlsx(data, settings)
        } catch (error) {

        }
    }


    return (
        <div>
            <div className="header">
                <div>
                    <img src="logos_header.png" />
                </div>
                <div style={{ display: 'inline-flex' }}>
                    <div onClick={downloadReport} className="downloadReportButton">Reporte inscripción talleres</div>
                    <div onClick={logOut} className="inscripcion">
                        <div>Logout</div>
                    </div>
                </div>
            </div>
            <div className="adminDash">

                {companies !== "" &&
                    <div>
                        <div className="adminItem">
                            <div >Elija Empresa:</div>
                            <select style={{ width: '200px' }} name="company_id" value={companySelected} onChange={selectCompany}>
                                <option>Seleccione la empresa</option>
                                {companies !== undefined &&
                                    companies.map(item => {
                                        return (<option value={item.business_name}>{item.business_name}</option>);
                                    })
                                }
                            </select>
                        </div>
                    </div>
                }
                {companyInfo !== "" &&
                    <div className="contentDownloads">
                        {companyInfo.estados_financieros !== null &&
                            <div>
                                <a className="downloadButton" href={`${url}download/${companyInfo.estados_financieros.substr(5, companyInfo.estados_financieros.length)}`} download>
                                    descargar estados financieros
                                </a>
                            </div>
                        }
                        {companyInfo.fotocopia_rut !== null &&
                            <div>
                                <a className="downloadButton" href={`${url}download/${companyInfo.fotocopia_rut.substr(5, companyInfo.fotocopia_rut.length)}`} download>
                                    descargar rut
                                </a>
                            </div>
                        }
                        {companyInfo.certificado_existencia !== null &&
                            <div>
                                <a className="downloadButton" href={`${url}download/${companyInfo.certificado_existencia.substr(5, companyInfo.certificado_existencia.length)}`} download>
                                    descargar certificado de existencia
                                </a>
                            </div>
                        }
                        {companyInfo.carta_inscripcion !== null &&
                            <div>
                                <a className="downloadButton" href={`${url}download/${companyInfo.carta_inscripcion.substr(5, companyInfo.carta_inscripcion.length)}`} download>
                                    descargar carta de inscripcion
                                </a>
                            </div>
                        }
                        {companyInfo.fotocopia_cedula !== null &&
                            <div>
                                <a className="downloadButton" href={`${url}download/${companyInfo.fotocopia_cedula.substr(5, companyInfo.fotocopia_cedula.length)}`} download>
                                    descargar cedula
                                </a>
                            </div>
                        }
                        {companyInfo.alianzas !== null &&
                            <div>
                                <a className="downloadButton" href={`${url}download/${companyInfo.alianzas.substr(5, companyInfo.alianzas.length)}`} download>
                                    descargar Alianzas
                                </a>
                            </div>
                        }
                        {companyInfo.anexoDos !== null &&
                            <div>
                                <a className="downloadButton" href={`${url}download/${companyInfo.anexoDos.substr(5, companyInfo.anexoDos.length)}`} download>
                                    descargar cedula
                                </a>
                            </div>
                        }
                        {companyInfo.anexoTres !== null &&
                            < div >
                                <a className="downloadButton" href={`${url}download/${companyInfo.anexoTres.substr(5, companyInfo.anexoTres.length)}`} download>
                                    descargar cedula
                                </a>
                            </div>
                        }
                    </div>
                }

                {companyInfo !== "" &&
                    <div className="content">
                        <div className="fields">
                            <div className="field">Radicado: </div>
                            <div className="field">
                                {companyInfo.id}
                            </div>
                        </div>
                        <div className="fields">
                            <div className="field">Fecha en que se inscribio: </div>
                            <div className="field">
                                {companyInfo.created_at.substr(0, 10)}
                            </div>
                        </div>
                        <div className="fields">
                            <div className="field">Tipo de organización: </div>
                            <div className="field">
                                {companyInfo.organization_type}
                            </div>
                        </div>
                        <br />
                        <div className="fields">
                            <div className="field">Razón social: </div>
                            <div className="field">
                                {companyInfo.business_name}
                            </div>

                        </div>
                        <br />
                        <div className="fields">
                            <div className="field">Número de indentificación: </div>
                            <div className="field">{companyInfo.identification_number}</div>

                        </div>
                        <br />
                        <div className="fields">
                            <div className="field">Sector: </div>
                            <div className="field">{companyInfo.sector}</div>

                        </div>
                        <br />
                        <div className="fields">
                            <div className="field">Código CIIU: </div>
                            <div className="field">{companyInfo.ciiu}</div>

                        </div>
                        <br />
                        <div className="fields">
                            <div className="field">Tamaño de la empresa: </div>
                            <div className="field">{companyInfo.size}</div>

                        </div>
                        <br />
                        <div className="fields">
                            <div className="field">Número de empleados: </div>
                            <div className="field">{companyInfo.employees_quantity}</div>

                        </div>
                        <br />
                        <div className="fields">
                            <div className="field">Ventas anuales: </div>
                            <div className="field">${companyInfo.annual_sales.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}</div>

                        </div>
                        <br />
                        <div className="fields">
                            <div className="field">Exportaciones: </div>
                            <div className="field">{companyInfo.exports}USD</div>

                        </div>
                        <br />
                        <div className="fields">
                            <div className="field">Departamento: </div>
                            <div className="field">{companyInfo.municipalities.department.name}</div>
                        </div>
                        <br />
                        <div className="fields">
                            <div className="field">Municipalidad: </div>
                            <div className="field">{companyInfo.municipalities.name}</div>
                        </div>
                        <br />
                        <div className="fields">
                            <div className="field">Nombre del contacto que diligencia el formulario: </div>
                            <div className="field">{companyInfo.lider}</div>

                        </div>
                        <br />
                        <div className="fields">
                            <div className="field">Número de identificación del contacto que diligencia el formulario: </div>
                            <div className="field">{companyInfo.responsable_identification}</div>

                        </div>
                        <br />
                        <div className="fields">
                            <div className="field">Cargo de la persona que
                                diligencia el formulario de
                                inscripción de la organización o
                                empresa: </div>
                            <div className="field">{companyInfo.responsible_cargo}</div>

                        </div>
                        <br />
                        <div className="fields">
                            <div className="field">Correo electrónico
                                institucional de contacto: </div>
                            <div className="field">{companyInfo.personal_responsible_email}</div>

                        </div>
                        <br />
                        <div className="fields">
                            <div className="field">Teléfono institucional de
                                contacto (sin comas, puntos o guiones): </div>
                            <div className="field">{companyInfo.institutional_telephone}</div>

                        </div>
                        <br />
                        <div className="fields">
                            <div className="field">Número de celular personal de
                                quien diligencia el formulario de
                                inscripción (sin comas, puntos o guiones): </div>
                            <div className="field">
                                {companyInfo.personal_mobile}
                            </div>
                        </div>
                        <br />
                        <div className="fields">
                            <div className="field">¿Cómo se enteró de este
                                proyecto?: </div>
                            <div className="field">
                                {companyInfo.find_out}
                            </div>
                        </div>
                        <br />
                        <div className="fields">
                            <div className="field">¿La presidencia o
                                dirección general de la
                                empresa está a cargo de
                                una o más mujeres?: </div>
                            <div className="field">
                                {companyInfo.women_in_charge}
                            </div>
                        </div>
                        <br />
                        <div className="fields">
                            <div className="field">¿Al menos el 51% de la
                                empresa es propiedad de
                                una o más mujeres?: </div>
                            <div className="field">
                                {companyInfo.owned_by_women_percentage}
                            </div>
                        </div>
                        <br />
                        <div className="fields">
                            <div className="field">Se auto reconoce como
                                indígena,
                                afrocolombiano(a) o
                                gitano(a); o hace parte
                                del registro de víctimas
                                del conflicto armado: </div>
                            <div className="field">
                                {companyInfo.other_ethnicity}
                            </div>
                        </div>
                        <br />
                        <div className="fields">
                            <div className="field">Usted o su empresa ha participado anteriormente en programas de Colombia Productiva: </div>
                            <div className="field">
                                {companyInfo.participate_before}
                                {companyInfo.programs}
                            </div>
                        </div>
                        <br />
                        <div className="fields">
                            <div className="field">Url del video: </div>
                            <div className="field">
                                {companyInfo.video}
                            </div>
                        </div>
                        <br />
                        <div className="fields">
                            <div className="field">Autorización para el uso
                                de datos personales y
                                para el registro en la
                                plataforma CLN (COMPRA LO NUESTRO), si no se
                                encuentra aún registrado: </div>
                            <div className="field">
                                {companyInfo.authorization_yes_no}
                            </div>
                        </div>
                        <a style={{ visibility: 'hidden' }} className="" href={`${url}download/${companyInfo.estados_financieros.substr(5, companyInfo.estados_financieros.length)}`} download>
                            descargar estados financieros
                        </a>
                    </div>
                }
            </div>
        </div >
    )
}

export default AdminDashboard
