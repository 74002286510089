import './App.css';
import Files from './components/files/Files';
import AdminDashboard from './components/adminDashboard/AdminDashboard';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ProtectedRoute from './components/login/ProtectedRoute';
import Login from './components/login/Login';
import UserInscription from './components/usersInscription/UserInscription';
import Landing from './components/landing/Landing';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        
        <Switch>
          <ProtectedRoute exact path="/admin" component={AdminDashboard} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/home" component={Landing} />

          {/* <Route exact path="/admin" component={AdminDashboard} /> */}
        </Switch>
        <Route exact path="/" component={Files} />
        <Route exact path="/InscripcionTaller" component={UserInscription} />

      </BrowserRouter>
      <div className="footerLogin">
        <img style={{ transform: 'translate(0, 10px)' }} src={require('./footerCarrousel.png').default} alt="" width="100%" />
      </div>
    </div>
  );
}

export default App;
