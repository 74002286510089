import React, { useState, useEffect, useRef } from 'react'
import './files.css'
import CurrencyInput from 'react-currency-input-field';
import { Link } from 'react-router-dom';
import axios from 'axios'

const Files = () => {

    const [file, setFile] = useState('')
    const [file2, setFile2] = useState('')
    const [file3, setFile3] = useState('')
    const [file4, setFile4] = useState('')
    const [file5, setFile5] = useState('')
    const [file6, setFile6] = useState('')
    const [file7, setFile7] = useState('')
    const [file8, setFile8] = useState('')
    const [file9, setFile9] = useState('')
    const [departments, setDepartments] = useState([])
    const [sending, setsending] = useState('')
    const [companyType, setcompanyType] = useState('')
    const [municipios, setMunicipios] = useState([])
    const [formValues, setformValues] = useState({})
    const [error, setError] = useState('');
    const [ok, setok] = useState(false)
    console.log('formValues', formValues);

    useEffect(() => {
        departmentsList();
    }, [])


    const onchange = (e) => {
        console.log('e.target.files[0]', e.target.files[0]);

        if (e.target.files[0].type === 'application/pdf') {
            if (e.target.name === '1') {
                setFile(e.target.files[0])
            }
            else if (e.target.name === '2') {
                setFile2(e.target.files[0])
            }
            else if (e.target.name === '3') {
                setFile3(e.target.files[0])
            }
            else if (e.target.name === '4') {
                setFile4(e.target.files[0])
            }
            else if (e.target.name === '5') {
                setFile5(e.target.files[0])
            }
            else if (e.target.name === '6') {
                setFile6(e.target.files[0])
            }
            else if (e.target.name === '7') {
                setFile7(e.target.files[0])
            }
            else if (e.target.name === '8') {
                setFile8(e.target.files[0])
            }
             else if (e.target.name === '9') {
                 setFile9(e.target.files[0])
             }
            return;
        }

        alert('El formato de archivo debe ser pdf')
    }

    const departmentsList = async () => {
        const response = await axios.get('http://lantiasas.com/colproduct/api/departments');
        console.log('response', response);
        setDepartments(response.data)
    }


    const sendFile = async () => {

        if (file === '') {
            alert('falta adjuntar la carta de inscripcion')
            return false;
        }
        // if(file2 === ''){
        //     alert('falta adjuntar el acuerdo de condiciones')
        //     return false;
        // }
        if (file3 === '') {
            alert('falta adjuntar el certificado de existencia')
            return false;
        }
        if (file4 === '') {
            alert('falta adjuntar la fotocopia de la cedula')
            return false;
        }
        if (file5 === '') {
            alert('falta adjuntar la fotocopia del rut')
            return false;
        }
        if (file6 === '') {
            alert('falta adjuntar los estados financieros')
            return false;
        }
        // if (file9 === '') {
        //     alert('falta adjuntar las alianzas')
        //     return false;
        // }

        let url = "http://lantiasas.com/colproduct/api/savefile";

        const data = new FormData()
        data.append('carta_inscripcion', file)
        data.append('acuerdo_condiciones', file2)
        data.append('certificado_existencia', file3)
        data.append('fotocopia_cedula', file4)
        data.append('fotocopia_rut', file5)
        data.append('estados_financieros', file6)
        data.append('anexoDos', file7)
        data.append('anexoTres', file8)
        data.append('alianzas', file9)
        data.append('dita', formValues)

        const headers = {
            'Content-Type': 'multipart/form-data',
            'enctype': 'multipart/form-data'
        }

        const response = await axios.post(url, data);
        return response.data;
    }

    function numberWithCommas(x) {
        return x.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    const changeInput = async (event) => {
        console.log('event', event);
        console.log('event.target.value', numberWithCommas(event.target.value));

        if (event.target.name === 'annual_sales') {
            setformValues({
                ...formValues,
                [event.target.name]: event.target.value
            })
        } else {
            setformValues({
                ...formValues,
                [event.target.name]: event.target.value
            })
        }

        if (event.target.name === 'department') {
            try {
                const response = await axios.get(`http://lantiasas.com/colproduct/api/municipalities/${event.target.value}`);
                console.log('response munic', response.data);
                setMunicipios(response.data.sort())
            } catch (error) {

            }

        }

    }


    const submit = async (e) => {
        console.log('formValues', formValues);
        e.preventDefault();
        setsending('Enviando los datos...')
        let internalError = '';
        // if (formValues.name === '' || formValues.name === undefined) {
        //     internalError = 'El nombre de la empresa es requerido, ';
        // }
        if (formValues.lider === '' || formValues.lider === undefined) {
            internalError = 'El nombre de la persona que diligencia el formulario es requerido, ';
        }
        if (formValues.identification_number === '' || formValues.identification_number === undefined) {
            internalError = 'El número de identificación del contacto que diligencia el formulario es requerido, ';
        }
        if (formValues.identification_number_confirmation === '' || formValues.identification_number_confirmation === undefined) {
            internalError = 'La confirmación del número de identificación del contacto que diligencia el formulario es requerido, ';
        }
        if (formValues.business_name === '' || formValues.business_name === undefined) {
            internalError = internalError + 'La Razón social es requerida, ';
        }
        if (formValues.organization_type === '' || formValues.organization_type === undefined) {
            internalError = internalError + 'El Tipo de organización es requerido, ';
        }
        if (formValues.sector === '' || formValues.sector === undefined) {
            internalError = internalError + 'La sector es requerido, ';
        }
        if (formValues.size === '' || formValues.size === undefined) {
            internalError = internalError + 'El tamaño de la empresa es requerido, ';
        }
        if (formValues.employees_quantity === '' || formValues.employees_quantity === undefined) {
            internalError = internalError + 'La cantidad de empleados es requerida, ';
        }
        if (formValues.annual_sales === '' || formValues.annual_sales === undefined) {
            internalError = internalError + 'Los ingresos anuales son requeridos, ';
        }
        if (formValues.exports === '' || formValues.exports === undefined) {
            internalError = internalError + 'Las exportaciones son requeridas ';
        }
        if (formValues.responsible_cargo === '' || formValues.responsible_cargo === undefined) {
            internalError = internalError + 'El cargo del responsable que diligencia el formulario es requrido, ';
        }
        if (formValues.personal_responsible_email === '' || formValues.personal_responsible_email === undefined) {
            internalError = internalError + 'El correo del responsable que diligencia el formulario es requrido, ';
        }
        if (formValues.institutional_responsible_email === '' || formValues.institutional_responsible_email === undefined) {
            internalError = internalError + 'El correo institucional del responsable que diligencia el formulario es requrido, ';
        }
        if (formValues.institutional_telephone === '' || formValues.institutional_telephone === undefined) {
            internalError = internalError + 'El teléfono institucional del responsable que diligencia el formulario es requrido, ';
        }
        if (formValues.personal_mobile === '' || formValues.personal_mobile === undefined) {
            internalError = internalError + 'El teléfono personal del responsable que diligencia el formulario es requrido, ';
        }
        if (formValues.find_out === '' || formValues.find_out === undefined) {
            internalError = internalError + 'El medio por el cual se entero de el formulario es requerido, ';
        }
        if (formValues.women_in_charge === '' || formValues.women_in_charge === undefined) {
            internalError = internalError + 'El campo de si existen mujeres en el cargo de la presidencia es requerido, ';
        }
        // if (formValues.video === '' || formValues.video === undefined) {
        //     internalError = internalError + 'La url del video es requerida, ';
        // }
        if (formValues.owned_by_women_percentage === '' || formValues.owned_by_women_percentage === undefined) {
            internalError = internalError + `el campo de preguta: ¿Al menos el 51% de la
            empresa es propiedad de
            una o más mujeres? es requerido, `;
        }
        if (formValues.other_ethnicity === '' || formValues.other_ethnicity === undefined) {
            internalError = internalError + `Se auto reconoce como
            indígena,
            afrocolombiano(a) o
            gitano(a); o hace parte
            del registro de víctimas
            del conflicto armado es requerido, `;
        }
        if (formValues.participate_before === '' || formValues.participate_before === undefined) {
            internalError = internalError + ` Usted o su empresa ha
            participado
            anteriormente en programas de Colombia Productiva, `;
        }
        if (formValues.authorization_yes_no === '' || formValues.authorization_yes_no === undefined) {
            internalError = internalError + `Autorización para el uso
            de datos personales y
            para el registro en la
            plataforma CLN, si no se
            encuentra aún registrado es requerida, `;
        }
        if (formValues.municipality_id === '' || formValues.municipality_id === undefined) {
            internalError = internalError + 'El municipio es requerido ';
        }
        if (formValues.ciiu === '' || formValues.ciiu === undefined) {
            internalError = internalError + 'El código ciiu es requerido ';
        }
        if (formValues.responsable_identification === '' || formValues.responsable_identification === undefined) {
            internalError = internalError + 'El número de identificación de la persona que llena el formulario es requerido ';
        }
        if (internalError === '') {

            var rta = await sendFile()
            console.log('aca', rta);
            if (rta === false) {
                return;
            }
            formValues.id = rta;
            formValues.identification_number = formValues.doc_type + formValues.identification_number
            formValues.identification_number_confirmation = formValues.doc_type + formValues.identification_number_confirmation
            if (formValues.identification_number !== formValues.identification_number_confirmation) {
                console.log(formValues.identification_number, formValues.identification_number_confirmation);
                alert('La verificacion del número de identificación no coincide')
                return;
            }
            console.log('formValues-----------', formValues);
            //axios.post('http://localhost:8000/api/insertFile', formValues)
            axios.post('http://lantiasas.com/colproduct/api/insertFile', formValues)
                //axios.post('http://localhost:8000/api/savefile', data)
                .then(function (response) {
                    console.log(response);
                    setsending('')
                    alert('datos enviados correctamente');
                    setok(true);
                })
                .catch(function (error) {
                    console.log(error);
                    alert('se ha producido un error');
                });

            setError('');
        } else {
            setError(internalError);
        }


    }

    return (
        <>
            <div className="header">
                <div>
                    <img src="logos_header.png" />
                </div>
                <div className="inscripcion">
                    <Link style={{textDecoration:'none', color:'#fff'}} to='/login'>Login</Link>
                </div>
            </div>
            {ok === false ?
                <div className="loginBox">
                    <h3>Formulario de postulación para beneficiarios Encadenamientos Naranja II</h3>
                    <br />
                    <br />

                    <form >
                        {/* <div className="col-12 col-sm-8 col-md-11 inputs">
                        <label>Nombre de la empresa</label>
                        <input name="name" value={formValues.name} onChange={changeInput} type="text" className="form-control inputSearch" id="inputPassword2" placeholder="nombre de la empresa" />
                    </div>
                    <br /> */}


                        <div style={{ height: '120px' }} className="col-12 col-sm-8 col-md-11 inputs">
                            <label style={{ marginLeft: '20px' }}>Tipo de organización</label>
                            <div className="col-12 col-sm-8 col-md-12 inputs">
                                <select name="organization_type" value={formValues.organization_type} onChange={changeInput}>
                                    <option value="">seleccione tipo de organización</option>
                                    {/* <option value="Persona jurídica – Empresa">Persona jurídica – Empresa</option>
                                <option value="Persona natural">Persona natural</option>
                                <option value="Entidad sin ánimo de lucro">Entidad sin ánimo de lucro</option>
                                <option value="Organización no Gubernamental">Organización no Gubernamental</option>
                                <option value="Institución educativa">Institución educativa</option>
                                <option value="ONG">ONG</option>
                                <option value="Empresa social y comercial del estado">Empresa social y comercial del estado</option>
                                <option style={{ width: '100px' }} value="Otro tipo de sociedades, asociaciones, organizaciones civiles, fundaciones, científicas, ambientalistas entre otras">
                                    Otro tipo de sociedades, asociaciones,
                                    organizaciones civiles, fundaciones, científicas,
                                    ambientalistas entre otras</option> */}
                                    <option value="Empresa Sector Priorizado">Empresa Sector Productivo Priorizado por Colombia Productiva</option>
                                    <option value="Empresa de Economia Naranja">Empresa de Economia Naranja</option>

                                </select>
                            </div>
                        </div>
                        <br />
                        <div className="col-12 col-sm-8 col-md-11 inputs">
                            <label>Razón social</label>
                            <input name="business_name" value={formValues.business_name} onChange={changeInput} type="text" className="form-control inputSearch" id="inputPassword2" placeholder="Razón social" />
                        </div>
                        <br />
                        <div className="col-12 col-sm-8 col-md-11 inputs">
                            <label>Tipo de identificación</label>
                            <select name="doc_type" value={formValues.doc_type} onChange={changeInput}>
                                <option value="">Seleccione tipo de identificación</option>
                                <option value="NIT">NIT</option>
                                <option value="Cédula de ciudadanía">Cédula de ciudadanía</option>
                                <option value="Cédula de extranjería">Cédula de extranjería</option>
                                <option value="Pasaporte">Pasaporte</option>
                                <option value="Otro tipo de documento">Otro tipo de documento</option>
                            </select>
                        </div>

                        <div className="col-12 col-sm-8 col-md-11 inputs">
                            <label>Número de Identificación (Numerico sin puntos, comas o guiones),  (No incluya el Dígito de Verificación)</label>
                            <input name="identification_number" value={formValues.identification_number} onChange={changeInput} type="number" className="form-control inputSearch" id="inputPassword2" placeholder="número de identificación" />
                        </div>
                        <div className="col-12 col-sm-8 col-md-11 inputs">
                            <label>Confirmación del Número de Identificación</label>
                            <input name="identification_number_confirmation" value={formValues.identification_number_confirmation} onChange={changeInput} type="number" className="form-control inputSearch" id="inputPassword2" placeholder="número de identificación" />
                        </div>
                        <br />
                        <div style={{ height: '200px' }} className="col-12 col-sm-8 col-md-11 inputs">
                            <label>Sector</label>
                            <select name="sector" value={formValues.sector} onChange={changeInput}>
                                <option value="">Seleccione el sector</option>
                                <option value="Cacao y sus derivados">Cacao y sus derivados</option>
                                <option value="Cafés y derivados de café">Cafés y derivados de café</option>
                                <option value="Carne y productos cárnicos">Carne y productos cárnicos</option>
                                <option value="Lácteos y sus derivados">Lácteos y sus derivados</option>
                                <option value="Productos de Panadería y snacks">Productos de Panadería y snacks</option>
                                <option value="Palma y aceite de palma">Palma y aceite de palma</option>
                                <option value="Piscicultura, acuícola, pesquero y derivados de la pesca">Piscicultura, acuícola, pesquero y derivados de la pesca</option>
                                <option value="Otros alimentos procesados y productos de la agroindustria">Otros alimentos procesados y productos de la agroindustria</option>
                                <option value="Construcción (actividad edificadora y obras civiles)">Construcción (actividad edificadora y obras civiles)</option>
                                <option value="Fabricación de materiales y productos metálicos y minerales no metálicos para la construcción">Fabricación de materiales y productos metálicos y minerales no metálicos para la construcción</option>
                                <option value="Fabricación de productos químicos (diferentes a cosméticos, aseo y farmacéuticos)">Fabricación de productos químicos (diferentes a cosméticos, aseo y farmacéuticos)</option>
                                <option value="Fabricación de cosméticos y productos de aseo">Fabricación de cosméticos y productos de aseo</option>
                                <option value="Fabricación de productos farmacéuticos">Fabricación de productos farmacéuticos</option>
                                <option value="Fabricación de productos de plástico">Fabricación de productos de plástico</option>
                                <option value="Fabricación de productos de caucho">Fabricación de productos de caucho</option>
                                <option value="Fabricación o ensamble (vehículos, motocicletas, astillero, aeronáutico) y sus partes">Fabricación o ensamble (vehículos, motocicletas, astillero, aeronáutico) y sus partes</option>
                                <option value="Fabricación de papel, cartón y productos de papel y cartón">Fabricación de papel, cartón y productos de papel y cartón</option>
                                <option value="Servicios de edición (libros, periódicos, revistas, impresiones y otras publicaciones)">Servicios de edición (libros, periódicos, revistas, impresiones y otras publicaciones)</option>
                                <option value="Sistema moda (Textil, confección, cuero, calzado y marroquinería)">Sistema moda (Textil, confección, cuero, calzado y marroquinería)</option>
                                <option value="Otras industrias manufactureras">Otras industrias manufactureras</option>
                                <option value="Actividades artísticas, cinematográficas, audiovisuales, entretenimiento y recreación">Actividades artísticas, cinematográficas, audiovisuales, entretenimiento y recreación</option>
                                <option value="Comercialización de productos al por mayor y al por menor">Comercialización de productos al por mayor y al por menor</option>
                                <option value="Reparación de vehículos, motocicletas y sus partes">Reparación de vehículos, motocicletas y sus partes</option>
                                <option value="Software y tecnologías de la información">Software y tecnologías de la información</option>
                                <option value="Tercerización de procesos de negocios (BPO, KPO, ITO)">Tercerización de procesos de negocios (BPO, KPO, ITO)</option>
                                <option value="Turismo (Alojamiento, agencias de viajes, operadores, transporte de pasajeros)">Turismo (Alojamiento, agencias de viajes, operadores, transporte de pasajeros)</option>
                                <option value="Economía Naranja (comprende actividades de diseño, medios digitales, agencias de noticias, patrimonio cultural)">Economía Naranja (comprende actividades de diseño, medios digitales, agencias de noticias, patrimonio cultural)</option>
                                <option value="Servicios Basados en Conocimiento SBC (incluye audiovisual, publicidad, mercadeo, educación, servicios editoriales y gráficos, diseño arquitectónico, telemedicina, artes escénicas y música)">Servicios Basados en Conocimiento SBC (incluye audiovisual, publicidad, mercadeo, educación, servicios editoriales y gráficos, diseño arquitectónico, telemedicina, artes escénicas y música)</option>
                                <option value="Otros servicios">Otros servicios</option>
                            </select>
                            <br />
                            <br />
                            <div>
                                <label>
                                    Código CIIU (campo alfanumérico) (Ver Anexo No. 3 - Códigos CIIU sectores Colombia Productiva y de Economía Naranja)
                                </label>
                                <input name="ciiu" value={formValues.ciiu} onChange={changeInput} type="text" className="form-control inputSearch" id="inputPassword2" placeholder="Código CIIU" />
                            </div>
                        </div>
                        <div style={{ backgroundColor: 'rgb(241, 240, 240)', borderRadius: '10px', paddingTop: '20px', marginLeft: '47px', width: '92%' }}>
                            <label style={{ marginLeft: '70px' }}>Tamaño de la empresa ( elegir el rango de acuerdo a los ingresos del año que la empresa presentará para la postulación 2019 o 2020 )</label>
                            <div style={{ display: 'inline-flex', backgroundColor: 'rgb(241, 240, 240)', marginBottom: '30px', color: 'color: rgb(94, 92, 92)', marginLeft: '50px' }}>
                                <div style={{ width: '31%', marginRight: '20px', borderRadius: '10px', backgroundColor: 'rgb(241, 240, 240)', padding: '10px' }}>
                                    <label style={{ cursor: 'pointer' }} onClick={() => setcompanyType('Manufactura')}>Para empresas de Manufactura <strong>click aquí</strong></label>
                                    {companyType === 'Manufactura' &&
                                        <select name="size" value={formValues.size} onChange={changeInput}>
                                            <option value="">Seleccione tamaño</option>
                                            <option value="Micro">Micro  (Hasta  839.007.741 UVT)</option>
                                            <option value="Pequeña">Pequeña (Mayor 839.007.741 UVT - Hasta  7.299.256.965 UVT)</option>
                                            <option value="Mediana">Mediana (Mayor 7.299.256.965 UVT - Hasta  61.833.869.955 UVT )</option>
                                            <option value="Grande">Grande (Mayor 61.833.869.955 UVT)</option>
                                        </select>
                                    }

                                </div>
                                <div style={{ width: '31%', marginRight: '20px', borderRadius: '10px', backgroundColor: 'rgb(241, 240, 240)', padding: '10px' }}>
                                    <label style={{ cursor: 'pointer' }} onClick={() => setcompanyType('Servicios')}>Para empresas de Servicios <strong>click aquí</strong></label>
                                    {companyType === 'Servicios' &&
                                        <select name="size" value={formValues.size} onChange={changeInput}>
                                            <option value="">Seleccione tamaño</option>
                                            <option value="Micro">Micro (Hasta  1.174.603.716 UVT)</option>
                                            <option value="Pequeña">Pequeña (Mayor 1.174.603.716 UVT - Hasta  4.698.379.257 UVT)</option>
                                            <option value="Mediana">Mediana (Mayor  4.698.379.257 UVT - Hasta  17.199.391.638 UVT)</option>
                                            <option value="Grande">Grande (Mayor 17.199.391.638 UVT)</option>
                                        </select>
                                    }
                                </div>
                                <div style={{ width: '31%', marginRight: '20px', borderRadius: '10px', backgroundColor: 'rgb(241, 240, 240)', padding: '10px' }}>
                                    <label style={{ cursor: 'pointer' }} onClick={() => setcompanyType('Comercio')}>Para empresas de Comercio <strong>click aquí</strong></label>
                                    {companyType === 'Comercio' &&
                                        <select name="size" value={formValues.size} onChange={changeInput}>
                                            <option value="">Seleccione tamaño</option>
                                            <option value="Micro">Micro (Hasta  1.594.089.783 UVT)</option>
                                            <option value="Pequeña">Pequeña (Mayor  1.594.089.783 UVT - Hasta  15.353.595.972 UVT)</option>
                                            <option value="Mediana">Mediana (Mayor  15.353.595.972 UVT - Hasta  76.935.760.044 UVT)</option>
                                            <option value="Grande">Grande (Mayor 76.935.760.044 UVT)</option>
                                        </select>
                                    }
                                </div>
                            </div>
                        </div>

                        <br />
                        <div style={{ display: 'inline-flex', marginBottom: '30px', marginLeft: '48px', color: 'color: rgb(94, 92, 92)' }}>

                            <div style={{ width: '31%', marginRight: '20px', borderRadius: '10px', backgroundColor: 'rgb(241, 240, 240)', padding: '10px' }}>
                                <label>Número de empleados</label>
                                <input name="employees_quantity" value={formValues.employees_quantity} onChange={changeInput} type="number" className="form-control inputSearch" id="inputPassword2" placeholder="Número de empleados" />
                            </div>
                            <div style={{ width: '31%', marginRight: '20px', borderRadius: '10px', backgroundColor: 'rgb(241, 240, 240)', padding: '10px' }}>
                                <label>Ventas anuales (sin comas, puntos o guiones)</label>
                                {/* <input name="annual_sales" value={formValues.annual_sales} onChange={changeInput} type="text" className="form-control inputSearch" id="inputPassword2" placeholder="Ventas anuales" /> */}
                                <CurrencyInput
                                    id="annual_sales"
                                    style={{ width: '100%' }}
                                    name="annual_sales"
                                    placeholder="Ventas anuales"
                                    defaultValue={0}
                                    decimalsLimit={2}
                                    prefix="$"
                                    onValueChange={(value, name) => { console.log(value, name); changeInput({ target: { name: name, value: value } }) }}
                                />
                            </div>

                            <div style={{ width: '31%', marginRight: '20px', borderRadius: '10px', backgroundColor: 'rgb(241, 240, 240)', padding: '10px' }}>
                                <label>Exportaciones (USD)</label>
                                {/* <input name="exports" value={formValues.exports} onChange={changeInput} type="number" className="form-control inputSearch" id="inputPassword2" placeholder="Exportaciones" /> */}
                                <CurrencyInput
                                    id="exports"
                                    style={{ width: '100%' }}
                                    name="exports"
                                    placeholder="Exportaciones"
                                    defaultValue={0}
                                    decimalsLimit={2}
                                    onValueChange={(value, name) => { console.log(value, name); changeInput({ target: { name: name, value: value } }) }}
                                />
                            </div>
                        </div>
                        <br />
                        <br />
                        <div style={{ height: '150px' }} className="col-12 col-sm-8 col-md-11 inputs">
                            <label>Departamento</label>
                            {departments.length > 0 &&
                                <select name="department" value={formValues.department} onChange={changeInput}>
                                    <hr />
                                    <option>Seleccione el departamento</option>
                                    {departments.map(item => {
                                        return <option value={item.id}>{item.name}</option>;
                                    })}
                                </select>
                            }
                            {municipios.length > 0 &&
                                <div>
                                    <label>Municipalidad</label>
                                    <select name="municipality_id" value={formValues.municipality_id} onChange={changeInput}>

                                        <hr />
                                        <option>Seleccione el municipio</option>
                                        {municipios.map(item => {
                                            return <option value={item.id}>{item.name}</option>;
                                        })}

                                    </select>
                                </div>
                            }
                        </div>
                        <br />
                        <div className="col-12 col-sm-8 col-md-11 inputs">
                            <label>Nombre del contacto que diligencia el formulario</label>
                            <input name="lider" value={formValues.lider} onChange={changeInput} type="text" className="form-control inputSearch" id="inputPassword2" placeholder="nombre del responsable" />
                        </div>
                        <br />
                        <div className="col-12 col-sm-8 col-md-11 inputs">
                            <label>Número de identificación del contacto que diligencia el formulario</label>
                            <input name="responsable_identification" value={formValues.responsable_identification} onChange={changeInput} type="number" className="form-control inputSearch" id="inputPassword2" placeholder="Número de identificación" />
                        </div>
                        <br />
                        <div className="col-12 col-sm-8 col-md-11 inputs">
                            <label>Cargo de la persona que
                                diligencia el formulario de
                                inscripción de la organización o
                                empresa
                            </label>
                            <input name="responsible_cargo" value={formValues.responsible_cargo} onChange={changeInput} type="text" className="form-control inputSearch" id="inputPassword2" placeholder="Cargo" />
                        </div>
                        <br />
                        <div className="col-12 col-sm-8 col-md-11 inputs">
                            <label>Correo electrónico
                                institucional de contacto</label>
                            <input name="institutional_responsible_email" value={formValues.institutional_responsible_email} onChange={changeInput} type="email" className="form-control inputSearch" id="inputPassword2" placeholder="Correo electrónico institucional de contacto" />
                        </div>
                        <br />
                        <div className="col-12 col-sm-8 col-md-11 inputs">
                            <label>Correo electrónico
                                personal de contacto</label>
                            <input name="personal_responsible_email" value={formValues.personal_responsible_email} onChange={changeInput} type="email" className="form-control inputSearch" id="inputPassword2" placeholder="Correo electrónico personal de contacto" />
                        </div>
                        <br />
                        <div className="col-12 col-sm-8 col-md-11 inputs">
                            <label>Teléfono institucional de
                                contacto (sin comas, puntos o guiones)</label>
                            <input name="institutional_telephone" value={formValues.institutional_telephone} onChange={changeInput} type="number" className="form-control inputSearch" id="inputPassword2" placeholder="Teléfono institucional de contacto" />
                        </div>
                        <br />
                        <div className="col-12 col-sm-8 col-md-11 inputs">
                            <label>Número de celular personal de
                                quien diligencia el formulario de
                                inscripción (sin comas, puntos o guiones)
                            </label>
                            <input name="personal_mobile" value={formValues.personal_mobile} onChange={changeInput} type="number" className="form-control inputSearch" id="inputPassword2" placeholder="Celular persona de contacto" />
                        </div>
                        <br />
                        <div className="col-12 col-sm-8 col-md-11 inputs">
                            <label>¿Cómo se enteró de este
                                proyecto?</label>
                            <select name="find_out" value={formValues.find_out} onChange={changeInput}>
                                <option value="Redes sociales">Seleccione la opción</option>
                                <option value="Página web de Colombia Productiva">Página web de Colombia Productiva</option>
                                <option value="Redes sociales">Redes sociales</option>
                                <option value="Referencia de algún conocido">Referencia de algún conocido</option>
                                <option value="Medios de comunicación">Medios de comunicación</option>
                                <option value="Correo electrónico">Correo electrónico</option>
                                <option value="Otro">Otro</option>
                            </select>
                        </div>
                        <br />
                        <div className="col-12 col-sm-8 col-md-11 inputs">
                            <label>¿La presidencia o
                                dirección general de la
                                empresa está a cargo de
                                una o más mujeres?</label>
                            <br />
                            <div style={{ width: '100px' }}>
                                <input onChange={changeInput} type="radio" value="Si" name="women_in_charge" /> Si
                                <input style={{ marginLeft: '10px' }} onChange={changeInput} type="radio" value="No" name="women_in_charge" /> No
                            </div>
                        </div>
                        <br />
                        <div className="col-12 col-sm-8 col-md-11 inputs">
                            <label>¿Al menos el 51% de la
                                empresa es propiedad de
                                una o más mujeres?</label>
                            <br />
                            <div style={{ width: '100px' }}>
                                <input onChange={changeInput} type="radio" value="Si" name="owned_by_women_percentage" /> Si
                                <input style={{ marginLeft: '10px' }} onChange={changeInput} type="radio" value="No" name="owned_by_women_percentage" /> No
                            </div>
                        </div>
                        <br />
                        <div className="col-12 col-sm-8 col-md-11 inputs">
                            <label>Se auto reconoce como
                                indígena,
                                afrocolombiano(a) o
                                gitano(a); o hace parte
                                del registro de víctimas
                                del conflicto armado.</label>
                            <br />
                            <div style={{ width: '100px' }}>
                                <input onChange={changeInput} type="radio" value="Si" name="other_ethnicity" /> Si
                                <input style={{ marginLeft: '10px' }} onChange={changeInput} type="radio" value="No" name="other_ethnicity" /> No
                            </div>
                        </div>
                        <br />
                        <div style={{ height: '180px' }} className="col-12 col-sm-8 col-md-11 inputs">
                            <label style={{ alignItems: 'left' }}>Usted o su empresa ha participado anteriormente en programas de Colombia Productiva, en caso de ser afirmativo, escriba los programas en los que ha participado seguidos de una coma.</label>
                            <br />
                            <div style={{ width: '100px' }}>
                                <input onChange={changeInput} type="radio" value="Si" name="participate_before" /> Si
                                <input style={{ marginLeft: '10px' }} onChange={changeInput} type="radio" value="No" name="participate_before" /> No
                            </div>
                            <input style={{ marginTop: '10px' }} name="programs" value={formValues.programs} onChange={changeInput} type="text" className="form-control inputSearch" id="inputPassword2" placeholder="programas en los que ha participado" />
                        </div>
                        <br />
                        <div className="col-12 col-sm-8 col-md-11 inputs">
                            <label>Ingrese el enlace de acceso y/o descarga al video de intención de participación</label>
                            <input name="video" value={formValues.video} onChange={changeInput} type="text" className="form-control inputSearch" id="inputPassword2" placeholder="url del video" />
                        </div>
                        <br />
                        <div className="col-12 col-sm-8 col-md-11 inputs">
                            <label>Autorización para el uso
                                de datos personales y
                                para el registro en la
                                plataforma CLN (COMPRA LO NUESTRO), si no se
                                encuentra aún registrado</label>
                            <br />
                            <div style={{ width: '100px' }}>
                                <input onChange={changeInput} type="radio" value="Si" name="authorization_yes_no" /> Si
                                <input style={{ marginLeft: '10px' }} onChange={changeInput} type="radio" value="No" name="authorization_yes_no" /> No
                            </div>

                        </div>
                        <br />

                    </form>
                    <div>
                        <hr />
                    </div>
                    <div className="form-group inputs">
                        <div><label htmlFor="exampleFormControlFile1">Carta de Inscripción, declaración y compromiso firmada por gerente (Anexo 1)</label></div>
                        <div style={{ marginLeft: '20px' }}> <input type="file" accept="application/pdf" name="1" id="files" onChange={onchange} className="btn btn primary" /></div>
                    </div>
                    <div className="form-group inputs">
                        <div><label htmlFor="exampleFormControlFile1"> (Soporte de alianzas 1)</label></div>
                        <div style={{ marginLeft: '20px' }}> <input type="file" accept="application/pdf" name="7" id="files7" onChange={onchange} className="btn btn primary" /></div>
                    </div>
                    <div className="form-group inputs">
                        <div><label htmlFor="exampleFormControlFile1"> (Soporte de alianzas 2)</label></div>
                        <div style={{ marginLeft: '20px' }}> <input type="file" accept="application/pdf" name="8" id="files8" onChange={onchange} className="btn btn primary" /></div>
                    </div>
                    <div className="form-group inputs">
                        <div><label htmlFor="exampleFormControlFile1"> (Soporte de alianzas 3)</label></div>
                        <div style={{ marginLeft: '20px' }}> <input type="file" accept="application/pdf" name="9" id="files9" onChange={onchange} className="btn btn primary" /></div>
                    </div>
                    {/* <div className="form-group inputs">
                    <div></div>
                    <div></div>
                    <label htmlFor="exampleFormControlFile1">Acuerdo y condiciones para la prestación del servicio firmada por gerente</label>
                    <input type="file" name="2" id="files2" onChange={onchange} className="btn btn primary" />
                </div> */}
                    <div className="form-group inputs">
                        <div></div>
                        <div></div>
                        <label htmlFor="exampleFormControlFile1">Certificado de existencia y representación legal con expedición no mayor a 30 días</label>
                        <input type="file" name="3" id="files3" accept="application/pdf" onChange={onchange} className="btn btn primary" />
                    </div>
                    <div className="form-group inputs">
                        <div></div>
                        <div></div>
                        <label htmlFor="exampleFormControlFile1">Fotocopia de la cédula del Representante Legal o quien haga sus veces.</label>
                        <input type="file" name="4" id="files4" accept="application/pdf" onChange={onchange} className="btn btn primary" />
                    </div>
                    <div className="form-group inputs">
                        <div></div>
                        <div></div>
                        <label htmlFor="exampleFormControlFile1">Fotocopia del RUT.</label>
                        <input type="file" name="5" id="files5" accept="application/pdf" onChange={onchange} className="btn btn primary" />
                    </div>
                    <div className="form-group inputs">
                        <div></div>
                        <div></div>
                        <label htmlFor="exampleFormControlFile1">Estados financieros 2019 o 2020 firmados por contador y/o revisor fiscal.</label>
                        <input type="file" name="6" id="files6" accept="application/pdf" onChange={onchange} className="btn btn primary" />
                    </div>
                    {error !== '' && <div style={{ color: 'red' }}>{error}</div>}
                    {sending !== '' &&
                        <div>{sending}</div>
                    }
                    <div className="send pt-4">
                        <div onClick={submit} type="submit" className="btn btn-primary mb-5 px-5">Enviar</div>
                    </div>
                </div>
                :
                <div className="loginBox">
                    Gracias por diligenciar el formulario!
                </div>
            }

        </>
    );
}

export default Files;