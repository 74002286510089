import React from 'react'
import './landing.css'

const Landing = () => {
    return (
        <div className='landingMain'>
            <section className="background">
                <div className="row w-100" style={{ paddingTop:'10vh'}}>
                    <div className="col-lg-6 text-center mb-5">
                        <p className="pwhite t-1 mt-5">ENCADENAMIENTOS</p>
                        <p className="pwhite t-2"><b>NARANJA</b></p>
                        <div className="box-big">
                            <div className="boxes d-flex align-items-center" style={{}}>
                                <img src={require("./imgs/icon1.png").default} alt="" />
                                <p className="pwhite">75 encadenamientos efectivos a través
                                    del relacionamiento entre empresas de
                                    los sectores productivos y de la
                                    economía naranja.</p>
                            </div>
                            <div className="boxes d-flex align-items-center">
                                <img src={require("./imgs/icon2.png").default} alt="" />
                                <p className="pwhite">Construcción de propuestas que den
                                    solución a la medida de necesidades de
                                    las empresas del sector tradicional.</p>
                            </div>
                            <div className="boxes d-flex align-items-center">
                                <img src={require("./imgs/icon3.png").default} alt="" />
                                <p className="pwhite">Desarrollo de soluciones por parte de
                                    las empresas de economía naranja.</p>
                            </div>
                        </div>
                        <div className="dis-flex">
                            <div className="btn-div pt-1" style={{marginLeft:'5%', marginTop:'20px'}}>
                                <a href="http://vtsas.co/plataforma_vt_v2/"><b>CLIC AQUÍ PARA INGRESAR A LA PLATAFORMA</b></a>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-6 dis-flex mt-3">
                        {/* <div className="box-video"> */}
                        <iframe width="550" height="345" src="https://www.youtube.com/embed/cy2Gm0SBf04">
                        </iframe>
                        {/* </div> */}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Landing