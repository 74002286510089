import React, { useState } from 'react'
import axios from 'axios'
import './userInscription.css'

const UserInscription = () => {

    const [formvalues, setformvalues] = useState('')
    const [error, setError] = useState('');
    const [dataOk, setDataOk] = useState(false)

    const hadleInputChange = (event) => {
        setformvalues({
            ...formvalues,
            [event.target.name]: event.target.value
        })
    }


    const sendData = async () => {
        let internalError = '';
        if (formvalues.name === '' || formvalues.name === undefined) {
            internalError = internalError + 'El nombre es requerido, ';
        }
        if (formvalues.last_name === '' || formvalues.last_name === undefined) {
            internalError = internalError + 'La apellido es requerido, ';
        }
        if (formvalues.identification_document === '' || formvalues.identification_document === undefined) {
            internalError = internalError + 'La cedula es requerida, ';
        }
        if (formvalues.nit === '' || formvalues.nit === undefined) {
            internalError = internalError + 'El nit de la empresa es requerido, ';
        }
        if (formvalues.company === '' || formvalues.company === undefined) {
            internalError = internalError + 'La empresa es requerida, ';
        }
        if (formvalues.company_type === '' || formvalues.company_type === undefined) {
            internalError = internalError + 'El tipo de entidad es requerido, ';
        }
        if (formvalues.email === '' || formvalues.email === undefined) {
            internalError = internalError + 'El email es requerido, ';
        }
        if (formvalues.phone === '' || formvalues.phone === undefined) {
            internalError = internalError + 'El número de conttacto es requerido, ';
        }
        if (formvalues.authorization === '' || formvalues.authorization === undefined) {
            internalError = internalError + 'El campo de autorización para la recolección y el tratamiento de los datos es requerido, ';
        }
        if (formvalues.workshop === '' || formvalues.workshop === undefined) {
            internalError = internalError + 'El taller es requerido, ';
        }
        if (internalError === '') {
            setError('');
            try {
                let response = await axios.post('http://lantiasas.com/colproduct/api/createPresence', formvalues)
                console.log(response);
                alert('datos enviados')
                setDataOk(true)
            } catch (error) {

            }

        } else {
            setError(internalError);
        }
    }

    return (
        <div>
            <div className="header">
                <div>
                    <img src="logos_header.png" />
                </div>
            </div>
            {dataOk ?
                <div className="userInscriptionComponent">
                    <div style={{ width: '300px', marginRight: 'auto', marginLeft: 'auto', marginTop: '200px' }}>
                        ¡Inscripción terminada!
                    </div>
                </div>
                :
                <div className="userInscriptionComponent">
                    <h3 style={{ marginTop: '20px' }}>Formulario de Inscripción a Talleres</h3>
                    <form>
                        <div className="field">
                            <label>Nombre</label>
                            <input className="inputField" onChange={hadleInputChange} type="text" name="name" value={formvalues.name} />
                        </div>
                        <div className="field">
                            <label>Apellido</label>
                            <input className="inputField" onChange={hadleInputChange} type="text" name="last_name" value={formvalues.last_name} />
                        </div>
                        <div className="field">
                            <label>Cedula</label>
                            <input className="inputField" onChange={hadleInputChange} type="text" name="identification_document" value={formvalues.identification_document} />
                        </div>
                        <div className="field">
                            <label>Nit de la empresa</label>
                            <input className="inputField" onChange={hadleInputChange} type="text" name="nit" value={formvalues.nit} />
                        </div>
                        <div className="field">
                            <label>Nombre de la empresa</label>
                            <input className="inputField" onChange={hadleInputChange} type="text" name="company" value={formvalues.company} />
                        </div>
                        <div className="field">
                            <label>Sector empresarial</label>
                            <select className="inputField" name="company_type" value={formvalues.company_type} onChange={hadleInputChange}>
                                <option value="">Seleccione el sector empresarial</option>
                                <option value="Empresa de Economía Naranja">Empresa de Economía Naranja</option>
                                <option value="Empresa de Servicios">Empresa de Servicios</option>
                                <option value="Manufactura">Manufactura</option>
                                <option value="Agroindustria">Agroindustria</option>
                            </select>
                        </div>
                        <div className="field">
                            <label>Email</label>
                            <input className="inputField" onChange={hadleInputChange} type="text" name="email" value={formvalues.email} />
                        </div>
                        <div className="field">
                            <label>Número del contacto</label>
                            <input className="inputField" onChange={hadleInputChange} type="text" name="phone" value={formvalues.phone} />
                        </div>
                        <div className="field">
                            <label>Taller al que desea participar</label>
                            <select className="inputField" name="workshop" value={formvalues.workshop} onChange={hadleInputChange}>
                                <option value="">Seleccione taller</option>
                                <option value="Taller zona Occidente (Chocó, Valle del Cauca, Cauca, Nariño) Cali Jueves 9/12/2021 2:00 pm">Taller zona Occidente (Chocó, Valle del Cauca, Cauca, Nariño) Cali Jueves 9/12/2021 2:00 pm</option>
                                <option value="Nacional Virtual 10/12/2021 8:30 a.m.">Nacional Virtual 10/12/2021 8:30 a.m.</option>
                                <option value="Taller zona Norte (Guajira, Magdalena, Atlantico, San Andres y Providencia) Barranquilla Jueves 15/12/2021 2:00 p.m.">Taller zona Norte (Guajira, Magdalena, Atlantico, San Andres y Providencia) Barranquilla Jueves 15/12/2021 2:00 p.m.</option>
                            </select>
                        </div>
                        <div className="field">
                            <div style={{ display: 'inline-flex' }}>
                                <input style={{ marginTop: '7px', marginRight: '7px' }} onChange={hadleInputChange} type="radio" value="Si" name="authorization" />
                                <label>Autorización para la recolección y el tratamiento de los datos</label>
                            </div>
                            <br />
                        </div>
                        {error !== '' && <div style={{ width: '300px', color: 'red', marginLeft: 'auto', marginRight: 'auto' }}>{error}</div>}
                        <div onClick={sendData} className="sendButton">Enviar</div>
                    </form>
                </div>
            }
        </div>
    )
}

export default UserInscription
